import React, { Suspense, lazy, Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DefaultLayout, ContactLayout } from './components/Layout/index';
import ProtectedRoute from './ProtectedRoute';
import {
    NEW_ACCOUNT,
    ADMIN,
    STORE,
    APPLICATION,
    APPLICATION_STEPS,
    APPLICATION_COMPLETE,
    APPLICATION_CANCEL,
    APPLICATION_ACCEPTED_CORRECTIONS,
    APPLICATION_REJECTED
} from './constants/common.constant';

const Home = lazy(() => import("./components/Body/Home"));
const Confirm = lazy(() => import("./components/Body/Confirm"));
const NewAccount = lazy(() => import("./components/Body/NewAccount"));
const ApplicationComplete = lazy(() => import("./components/Body/ApplicationComplete"));
const Agreement = lazy(() => import("./components/Body/Agreement"));
const Signin = lazy(() => import("./components/Signin"));
const Signup = lazy(() => import("./components/Signup"));
const MailRegister = lazy(() => import("./components/RegisterMail"));
const Profile = lazy(() => import("./components/Body/Profile"));
const Application = lazy(() => import("./components/Body/Application"));
const ApplicationSteps = lazy(() => import("./components/Body/ApplicationSteps"));
const Store = lazy(() => import("./components/Body/Store"));
const StoreEdit = lazy(() => import("./components/Body/Store/Edit"));
const Deposit = lazy(() => import("./components/Body/Store/Deposit"));
const DepositDetail = lazy(() => import("./components/Body/Store/Deposit/DepositDetail"));
const DepositDetailAll = lazy(() => import("./components/Body/Store/Deposit/DepositDetailAll"));
const Summary = lazy(() => import("./components/Body/Store/Summary"));
const SummaryMonthly = lazy(() => import("./components/Body/Store/SummaryMonthly"));
const InvoiceReceipt = lazy(() => import("./components/Body/Store/InvoiceReceipt"));
const Notification = lazy(() => import("./components/Body/Store/Notification"));
const Calendar = lazy(() => import("./components/Body/Store/Notification/Calendar"));
const Paygate = lazy(() => import("./components/Body/Store/Notification/Paygate"));
const Rules = lazy(() => import("./components/Body/Store/Notification/Rules"));
const Contact = lazy(() => import("./components/Body/Contact"));
const ContactCompleted = lazy(() => import("./components/Body/Contact/ContactCompleted"));
const AdminStore = lazy(() => import("./components/Body/AdminStore"));
const AdminDeposit = lazy(() => import("./components/Body/AdminStore/AdminDeposit"));
const AdminDepositDetail = lazy(() => import("./components/Body/AdminStore/AdminDeposit/AdminDepositDetail"));
const AdminInvoiceReceipt = lazy(() => import("./components/Body/AdminStore/AdminInvoiceReceipt"));
const AreaSetting = lazy(() => import("./components/Body/AdminStore/AreaSetting"));
const AdminNotification = lazy(() => import("./components/Body/AdminStore/Notification"));
const AdminCalendar = lazy(() => import("./components/Body/AdminStore/Notification/Calendar"));
const AdminPaygate = lazy(() => import("./components/Body/AdminStore/Notification/Paygate"));
const AdminRules = lazy(() => import("./components/Body/AdminStore/Notification/Rules"));
const ResetPassword = lazy(() => import("./components/Body/ResetPassword"));
const ForgotPassword = lazy(() => import("./components/Body/ForgotPassword"));
const Error = lazy(() => import("./components/Body/ErrorPage"));
const Document = lazy(() => import("./components/Body/Document"));
const DocumentCompleted = lazy(() => import("./components/Body/Document/DocumentCompleted"));
const InvoiceMonthly = lazy(() => import("./components/Body/Store/InvoiceReceipt/InvoiceMonthly"));
const ReceiptMonthly = lazy(() => import("./components/Body/Store/InvoiceReceipt/ReceiptMonthly"));
const Invoice = lazy(() => import("./components/Body/Store/InvoiceReceipt/Invoice"));
const Receipt = lazy(() => import("./components/Body/Store/InvoiceReceipt/Receipt"));
const ChangePassword = lazy(() => import("./components/Body/ChangePassword"));
const AdminInvoiceMonthly = lazy(() => import("./components/Body/AdminStore/AdminInvoiceReceipt/AdminInvoiceMonthly"));
const MonthlyCostByStore = lazy(() => import("./components/Body/AdminStore/AdminInvoiceReceipt/AdminInvoiceMonthly/MonthlyCostByStore"));
const CreditCardMonthlyFee = lazy(() => import("./components/Body/AdminStore/AdminInvoiceReceipt/AdminInvoiceMonthly/CreditCardMonthlyFee"));
const AdminReceiptMonthly = lazy(() => import("./components/Body/AdminStore/AdminInvoiceReceipt/AdminReceiptMonthly"));
const RejectedPage = lazy(() => import("./components/Body/RejectedPage"));
const SendMailSuccess = lazy(() => import("./components/RegisterMail/SendMailSuccess"));
const AccessDenied = lazy(() => import("./components/Body/AccessDenied"));

const AppRouter = () => {

    return (
        <Router>
            <Routes>
                <Route path="/" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <Home />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/home" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <Home />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/confirm" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION, APPLICATION_COMPLETE, APPLICATION_CANCEL]}>
                            <Confirm />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/new-account" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION, APPLICATION_COMPLETE, APPLICATION_CANCEL]}>
                            <NewAccount />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/application-complete" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION, APPLICATION_STEPS, APPLICATION_COMPLETE]}>
                            <ApplicationComplete />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/agreement" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <Agreement />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/signin" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <Signin />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/forgot-password" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ForgotPassword />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/signup/:token" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <Signup />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/mail/register" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <MailRegister />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/send-mail-success" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <SendMailSuccess />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/profile" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[]}>
                            <Profile />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/reset-password/:token" exact element={<Fragment>
                    <Suspense fallback={null}>
                        <ResetPassword />
                    </Suspense>
                </Fragment>} />
                <Route path="/application" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION]}>
                            <Application />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/application-steps" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION, APPLICATION_STEPS]}>
                            <ApplicationSteps />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Store />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-edit" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE, APPLICATION_ACCEPTED_CORRECTIONS]}>
                            <StoreEdit />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-deposit" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Deposit />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-deposit/store-deposit-detail/:merchantNo/:transactionType/:datetime" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <DepositDetail />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-deposit/store-deposit-detail/:paymentDate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <DepositDetailAll />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/summary" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Summary />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/summary-monthly" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <SummaryMonthly />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <InvoiceReceipt />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/invoice-monthly/:invoiceNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <InvoiceMonthly />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/receipt-monthly/:invoiceNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <ReceiptMonthly />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/invoice/:invoiceNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Invoice />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/receipt/:invoiceNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Receipt />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-notification" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Notification />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-notification/calendar" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Calendar />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-notification/paygate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Paygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-notification/rules" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Rules />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/contact" exact element={<ContactLayout>
                    <Suspense fallback={null}>
                        <Contact />
                    </Suspense>
                </ContactLayout>} />
                <Route path="/contact-completed" exact element={<ContactLayout>
                    <Suspense fallback={null}>
                        <ContactCompleted />
                    </Suspense>
                </ContactLayout>} />
                <Route path="/document" exact element={<ContactLayout>
                    <Suspense fallback={null}>
                        <Document />
                    </Suspense>
                </ContactLayout>} />
                <Route path="/document-completed/:type" exact element={<ContactLayout>
                    <Suspense fallback={null}>
                        <DocumentCompleted />
                    </Suspense>
                </ContactLayout>} />
                <Route path="/admin-store" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminStore />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/area-setting" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AreaSetting />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-deposit" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminDeposit />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-deposit/admin-deposit-detail/:transferDate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminDepositDetail />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-deposit/admin-deposit-detail/:transferDate/:transactionType/:merchantNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminDepositDetail />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-notification" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminNotification />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-invoice-receipt" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminInvoiceReceipt />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-invoice-receipt/admin-invoice-monthly/:yearMonth" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminInvoiceMonthly />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-invoice-receipt/admin-invoice-monthly/credit-card-monthly-fee/:month/:type" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <CreditCardMonthlyFee />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-invoice-receipt/admin-invoice-monthly/monthly-cost-by-store/:month/:no" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <MonthlyCostByStore />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-invoice-receipt/admin-receipt-monthly/:yearMonth" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminReceiptMonthly />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-notification/calendar" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminCalendar />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-notification/paygate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminPaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-notification/rules" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminRules />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/change-password" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[]}>
                            <ChangePassword />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/rejected" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION_REJECTED]}>
                            <RejectedPage />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />

                <Route path="/access-denied" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <AccessDenied />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="*" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <Error />
                    </Suspense>
                </DefaultLayout>} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
