import { configureStore, combineReducers } from '@reduxjs/toolkit';
import appSlice from './app/index';
import authSlice from './auth/index';
import applicationSlice from './application/index';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  // blacklist: ['deposit'],
  stateReconciler: autoMergeLevel2,
}
const rootReducer = combineReducers({
  app: appSlice.reducer,
  auth: authSlice.reducer,
  application: applicationSlice.reducer,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})
export let persistor = persistStore(store);