import React from 'react';
import './index.css';
import '../index.css';
import logoIndex03 from '../../../../utils/image/logo_index03.png';

function Header() {

    return (
        <div className="header top">
            <div className="container">
                <section className="headerContent01">
                    <div className="header-logo"><a href="https://choqi.co.jp/choqipay/"><img src={logoIndex03} alt="小さな感動をもっと。チョキペイ セルフ" /></a></div>
                    <div className="header-logo displayNone"><a href="https://choqi.co.jp/choqipay/"><img src={logoIndex03} alt="小さな感動をもっと。チョキペイ セルフ" /></a></div>
                    <nav className="pcheaderMenu01 pcOnly">
                        <ul className="pcheaderList01">
                            <li><a className="toReason smoothScroll" href="https://choqi.co.jp/choqipay/#reason">選ばれる理由</a></li>
                            <li><a className="toFeatures smoothScroll" href="https://choqi.co.jp/choqipay/#features">チョキペイの特長</a></li>
                            <li><a className="toPay smoothScroll" href="https://choqi.co.jp/choqipay/#pay">決済方法</a></li>
                            <li><a className="toFlow smoothScroll" href="https://choqi.co.jp/choqipay/#flow">導入の流れ</a></li>
                            <li><a className="toWorks" href="https://choqi.co.jp/choqipay/#flow">導入事例</a></li>
                            <li><a className="toFaq" href="https://choqi.co.jp/choqipay/faq/faq_list6.html">よくあるご質問</a></li>
                            <li className="login01"><button id="loginBtn" type="button"><span>LOGIN</span></button></li>
                        </ul>
                    </nav>
                </section>
                <div className="spBtn01">
                    <p id="spBtn01" className="right spOnly">
                        <span></span>
                        <span></span>
                        <span></span>
                    </p>
                </div>
                <nav id="menu" className="globalMenuArea01">
                    <div className="menuContent01 service">
                        <ul className="spMenu">
                            <li><a href="https://choqi.co.jp/choqipay/#reason" className="smoothScroll">選ばれる理由</a></li>
                            <li><a href="https://choqi.co.jp/choqipay/#features" className="smoothScroll">チョキペイの特長</a></li>
                            <li><a href="https://choqi.co.jp/choqipay/#pay" className="smoothScroll">決済方法</a></li>
                            <li><a href="https://choqi.co.jp/choqipay/#flow" className="smoothScroll">導入の流れ</a></li>
                            <li><a href="https://choqi.co.jp/choqipay/#flow">導入事例</a></li>
                            <li><a href="../faq/faq01.html">よくあるご質問</a></li>
                        </ul>
                        <div className="spLoginMenuArea01">
                            <p className="spLoginTitle"><img src="" alt="" />LOGIN</p>
                            <ul className="spLoginMenu">
                                <li><a href="https://choqipay.powerappsportals.com/SignIn">チョキペイ</a></li>
                                <li><a href="https://choqipay-online.powerappsportals.com/SignIn">チョキペイOnline</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Header;