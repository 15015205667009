import API_ENDPOINT from "../../constants/api_endpoint.constant";
import axios from '../../utils/axiosService';
import { getToken } from '../../utils/token';

axios.setHeader("Authorization", `Bearer ${getToken()}`);
axios.setHeader("Content-Type", `application/json`);

export default class AdminInvoiceReceiptService {
    
    getData(merchantNo) {
        return axios.get(`${API_ENDPOINT.ADMIN_INVOICE_RECEIPT}?agxMerchantNo=${merchantNo}`);
    };
}