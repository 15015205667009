import API_ENDPOINT from "../../constants/api_endpoint.constant";
import axios from '../../utils/axiosService';
import { getToken } from '../../utils/token';

axios.setHeader("Authorization", `Bearer ${getToken()}`);
axios.setHeader("Content-Type", `application/json`);

export default class DepositAdminService {

    getDataSelector(merchantNo) {
        return axios.get(`${API_ENDPOINT.ADMIN_DEPOSIT}/selector?agxMerchantNo=${merchantNo}`);
    };

    getAgxMerchants(merchantNo, agxPaymentDate) {
        return axios.get(`${API_ENDPOINT.MERCHANT}/admin-store`);
    };

    getDataTableModalPayment(agxPaymentDate) {
        return axios.get(`${API_ENDPOINT.ADMIN_DEPOSIT}/modal-payment?agxPaymentDate=${agxPaymentDate}`);
    };

    getDataTableDepositBreakdown(agxPaymentDate) {
        return axios.get(`${API_ENDPOINT.ADMIN_DEPOSIT}/deposit-breakdown?agxPaymentDate=${agxPaymentDate}`);
    };

    getDataAdminDeposit(merchantNoAdmin, agxPaymentDate, area, subArea, merchantNo) {
        return axios.get(`${API_ENDPOINT.ADMIN_DEPOSIT}?merchantNoAdmin=${merchantNoAdmin}&agxPaymentDate=${agxPaymentDate}&area=${area}&subArea=${subArea}&merchantNo=${merchantNo}`);
    };

    getDataAdminDepositDetail(merchantNoAdmin, agxPaymentDate, transactionType, merchantNo) {
        return axios.get(`${API_ENDPOINT.ADMIN_DEPOSIT}/${API_ENDPOINT.ADMIN_DEPOSIT_DETAIL}?merchantNoAdmin=${merchantNoAdmin}&agxPaymentDate=${agxPaymentDate}&transactionType=${transactionType}&merchantNo=${merchantNo}`);
    }
}