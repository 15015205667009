import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

function Footer() {

    return (
        <footer id="choqi-footer" role="contentinfo" className="footer">
            <div className="footer-bottom hidden-print">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                            <div className="xrm-editable-html xrm-attribute" data-languagecontext="æ—¥æœ¬èªž">
                                <div className="xrm-attribute-value">
                                    <div className="container">
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center flex-wrap col-sm-6">
                                                <a href={"https://choqi.co.jp/choqipay/faq/faq_list6.html"} target="_blank"
                                                    style={{ fontSize: '18px', color: '#ffffff', paddingRight: '15px' }}>よくあるご質問</a>
                                                <a href={process.env.REACT_APP_URL_CONTACT} target="_blank"
                                                    style={{ frontSize: '18px', color: '#ffffff', borderLeft: '1px solid #ffffff', paddingLeft: '15px' }}>お問い合わせ</a>
                                            </div>
                                            <div className="col-sm-6" style={{ textAlign: 'left' }}>
                                                <p>
                                                    <span>Copyright © </span>
                                                    2023
                                                    <span>. ChoQi </span>
                                                    <span>Co., Ltd.</span>
                                                    <span>All rights reserved .</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;