import API_ENDPOINT from "../../constants/api_endpoint.constant";
import axios from '../../utils/axiosService';
import { getToken } from '../../utils/token';

axios.setHeader("Authorization", `Bearer ${getToken()}`);
axios.setHeader("Content-Type", `application/json`);
export default class EditService {
    
    // getAgxPaymentBreakdownData(merchantNo, agxPaymentDate) {
    //     return axios.get(`${API_ENDPOINT.AGX_PAYMENT_BREAKDOWN}/user?agxMerchantNo=${merchantNo}&agxPaymentDate=${agxPaymentDate}`);
    // };
    // getAgxMerchantPaymentData(merchantNo, agxPaymentDate) {
    //     return axios.get(`${API_ENDPOINT.AGX_MERCHANT_PAYMENT}/user?agxMerchantNo=${merchantNo}&agxPaymentDate=${agxPaymentDate}`);
    // };
    getDateFromAgxPaymentManagement() {
        return axios.get(`${API_ENDPOINT.AGX_PAYMENT_MANAGEMENT}/user/date`);
    };
    getElementStoreDepositDetail(agxMerchantNo, transactionType, datetime) {
        return axios.get(`${API_ENDPOINT.AGX_PAYMENT_DETAIL}/store-deposit-detail?merchantNo=${agxMerchantNo}&transactionType=${transactionType}&datetime=${datetime}`);
    };
    getDepositData(merchantNo, agxPaymentDate) {
        return axios.get(`${API_ENDPOINT.DEPOSIT}?agxMerchantNo=${merchantNo}&agxPaymentDate=${agxPaymentDate}`);
    };
    getSummaryMonthlyData(agxMerchantNo) {
        return axios.get(`${API_ENDPOINT.SUMMARY_MONTHLY}?merchantNo=${agxMerchantNo}`);
    };
    getSummaryData(agxMerchantNo) {
        return axios.get(`${API_ENDPOINT.SUMMARY}?merchantNo=${agxMerchantNo}`);
    };
    getDataStoreDepositDetail(agxMerchantNo, datetime) {
        return axios.get(`${API_ENDPOINT.STORE_DEPOSIT_DETAIL}?merchantNo=${agxMerchantNo}&paymentDate=${datetime}`);
    };
}